import React from 'react';
import { styled } from 'linaria/react';

const InputWrapper = styled.div`
  width: 100%;
`;

const Input = styled.input`
  margin-top: 1rem;
  border: 1px solid #c8ccd0;
  border-radius: 2px;
  padding: 10px;
  width: 100%;
`;

interface Props {
  messages: string[];
  value: string;
  update: (val: string) => void;
  placeholder: string;
}

const FormInput = (props: Props) => {
  const { placeholder, value, update, messages } = props;
  const handleChange = (event: any) => {
    update(event.target.value);
  };
  const hasErrors = messages.length > 0;
  return (
    <InputWrapper>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        type="text"
        className={hasErrors ? 'danger' : ''}
      />
      {hasErrors && <p className="error-msgs">{messages.join(' ')}</p>}
    </InputWrapper>
  );
};

FormInput.defaultProps = {
  messages: [],
};

export default FormInput;
