import React, { useState } from 'react';
import { styled } from 'linaria/react';
import shortid from 'shortid';
import RichTextEditor from './RichTextEditor';
import FormInput from '../Form/FormInput';
import FormButton from '../Form/FormButton';
import axios from 'axios';
import { NewCommentRequestBody } from '../../types';
import CommentCard from './CommentCard';
import { trim } from 'lodash';
import { media } from '../../styles/utils';
import { Comment } from '../../types';
import Toast from '../Toast';

interface Props {
  comments: Comment[];
  blogSlug: string;
}

const CommentsSectionBody = styled.section`
  margin-top: 4rem;

  .existing {
    margin-bottom: 4rem;
  }

  .section-title {
    border-bottom: 1px dashed var(--green);
    padding-bottom: 0.5rem;
    margin-bottom: 1.5rem;
    margin-top: 0;
    line-height: 1.2;
    font-size: 1.8em;
    font-family: var(--font-heading);
  }

  .new .grip {
    display: none;
  }

  .row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    & > * {
      flex: 1;
      min-width: 160px;
      width: 100%;
      &:first-child {
        flex: 1;
        ${media.medium} {
          margin-right: 1rem;
        }
      }
    }
  }
`;

function getRandomAuthor() {
  const authors = [
    'John Travolta',
    'Eddie Murphy',
    'Albert Einstein',
    'Stephen Hawking',
    'Isaac Asimov',
    'Jeff Bezos',
    'Isaac Newton',
    'Steve Jobs',
    'Bill Gates',
    'Tim Berners-Lee',
    'Elon Musk',
    'Alan Turing',
    'Nikola Tesla',
    'Richard P. Feynman',
    'Michio Kaku',
    'Naval Ravikant',
    'Guido van Rossum',
    'Linus Torvalds',
    'Bjarne Stroustrup',
    'Donald Knuth',
    'Ada Lovelace',
  ];
  const rand = Math.floor(Math.random() * authors.length);
  return authors[rand];
}

interface FormDataI {
  author: string;
  message: string;
}
interface ErrorsMapI {
  [fieldName: string]: string[];
}

const CommentsSection = (props: Props) => {
  const defaultToast = {
    message: '',
    longMessage: '',
    visible: false,
  };
  const { comments, blogSlug } = props;
  const [placeholder, _setPlaceholder] = useState(getRandomAuthor());
  const [toast, setToast] = useState(defaultToast);
  const [processing, setProcessing] = useState(false);
  const [errorMessages, setErrorMessages] = useState({
    author: [],
    message: [],
  } as ErrorsMapI);
  const [formData, setFormData] = useState({
    author: '',
    message: '',
  } as FormDataI);
  const updateFormData = (key: string, value: any) => {
    setFormData({ ...formData, [key]: value });
  };

  const validateTextField = (value: string | undefined) => {
    return value && trim(value).length > 0;
  };

  const validate = (data: FormDataI) => {
    const errors = {
      author: [],
      message: [],
    } as ErrorsMapI;
    if (!validateTextField(data.author)) {
      errors.author.push("Why don't you say who you are, eh?");
    }
    if (!validateTextField(data.message)) {
      errors.message.push("Didn't you forget something?");
    }
    const isValid = Object.values(errors).every((list) => list.length === 0);
    return { errors, isValid };
  };

  const onSubmit = async () => {
    setProcessing(true);
    const { errors, isValid } = validate(formData);
    setErrorMessages(errors);
    if (!isValid) {
      setProcessing(false);
      return;
    }

    const submitData = {
      ...formData,
      postSlug: blogSlug,
    } as NewCommentRequestBody;
    try {
      await axios.post('/api/comments/', submitData);
      setFormData({
        author: '',
        message: '',
      });
      setToast({
        message: 'Submitted!',
        longMessage: 'Your comment will be visible in a couple of minutes.',
        visible: true,
      });
    } catch {
      setToast({
        message: 'Submit failed ¯\_(ツ)_/¯',
        longMessage: 'Something went wrong. Maybe try again later?',
        visible: true,
      });
    } finally {
      setProcessing(false);
    }
  };

  const unsetToast = () => {
    setToast(defaultToast);
  };

  return (
    <CommentsSectionBody>
      {toast.visible && (
        <Toast
          message={toast.message}
          longMessage={toast.longMessage}
          withButton={true}
          callback={unsetToast}
        />
      )}
      <h2 className="section-title">Join discussion</h2>
      <ul className="existing">
        {comments.map((comment) => (
          <li key={shortid.generate()}>
            <CommentCard comment={comment} />
          </li>
        ))}
      </ul>
      <div className="new">
        <RichTextEditor
          value={formData.message}
          messages={errorMessages.message}
          setValue={(value) => updateFormData('message', value)}
        />
        <div className="row">
          <FormInput
            placeholder={placeholder}
            value={formData.author}
            update={(value) => updateFormData('author', value)}
            messages={errorMessages.author}
          />
          <FormButton
            title="Submit"
            onClick={onSubmit}
            processing={processing}
          />
        </div>
      </div>
    </CommentsSectionBody>
  );
};

export default CommentsSection;

CommentsSection.defaultProps = {
  comments: [],
};
