import React from 'react';
import { graphql } from 'gatsby';
// import Img from 'gatsby-image';
import { styled } from 'linaria/react';
import Layout from '../components/Layout/Layout';
// import useBlogData from '../static_queries/useBlogData';
import CommentsSection from '../components/BlogPost/CommentsSection.tsx';
// import blogTemplateStyles from '../styles/templates/blog.module.scss';
// this component handles the blur img & fade-ins

const BlogPost = styled.article`
  .blog {
    &__info {
      &__title {
        margin-bottom: 30px;
        margin-top: 0;
        line-height: 1.2;
        font-size: 2.9em;
        font-family: var(--font-heading);
        .bordered {
          border-bottom: 0.18em solid var(--black);
          padding-bottom: 0.15em;
          line-height: 1.65em;
          font-weight: bold;
        }
      }
    }
    &__body {
      h2 {
        margin-bottom: 30px;
        margin-top: 0;
        line-height: 1.2;
        font-size: 2.2em;
        font-family: var(--font-heading);
        font-weight: bold;
      }
      p {
        font-size: 1.1rem;
      }
    }
  }
`;

export default function Blog(props) {
  const { data } = props;
  const blogData = data.markdownRemark;
  const comments = data.comments.edges
    .map(({ node }) => node.frontmatter)
    .sort((a, b) => {
      return a.date >= b.date ? -1 : 1;
    });
  // const allBlogData = useBlogData();

  // function getNextSlug(slug) {
  //   const allSlugs = allBlogData.map((blog) => {
  //     return blog.node.fields.slug;
  //   });
  //   const nextSlug = allSlugs[allSlugs.indexOf(slug) + 1];
  //   if (nextSlug !== undefined && nextSlug !== '') {
  //     return nextSlug;
  //   }
  //   return allSlugs[0];
  // }

  // const nextSlug = getNextSlug(data.fields.slug);

  return (
    <Layout>
      <BlogPost className="blog">
        {/* <figure className="blog__hero">
          <Img
            fluid={data.featuredImg.childImageSharp.fluid}
            alt={data.frontmatter.title}
          />
        </figure> */}
        <div className="blog__info">
          <h1 className="blog__info__title">
            <span className="bordered">{blogData.frontmatter.title}</span>
          </h1>
          <h3>by Michał Popiel on {blogData.frontmatter.date}</h3>
        </div>
        <div
          className="blog__body"
          dangerouslySetInnerHTML={{ __html: blogData.html }}
        />
        <div className="blog__footer">
          <CommentsSection
            blogSlug={blogData.fields.slug}
            comments={comments}
          />
          {/* <Link to={`blog/${nextSlug}`} className="footer__next">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              x="0px"
              y="0px"
              viewBox="0 0 26 26"
              enableBackground="new 0 0 26 26"
            >
              <path d="M23.021,12.294l-8.714-8.715l-1.414,1.414l7.007,7.008H2.687v2h17.213l-7.007,7.006l1.414,1.414l8.714-8.713  C23.411,13.317,23.411,12.685,23.021,12.294z" />
            </svg>
          </Link> */}
        </div>
      </BlogPost>
    </Layout>
  );
}

// dynamic page query, must occur within each post context
// $slug is made available by context from createPages call in gatsby-node.js
export const getPostData = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        tags
        title
        date(formatString: "MMMM Do, YYYY")
      }
      html
      featuredImg {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    comments: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/comments/" }
        frontmatter: { post: { eq: $slug } }
      }
    ) {
      edges {
        node {
          frontmatter {
            message
            post
            author
            date
          }
        }
      }
    }
  }
`;
