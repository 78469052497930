import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

const RECAPTCHA_CLIENT_KEY = process.env.GATSBY_RECAPTCHA_CLIENT || '';

async function check(humanKey: any) {
  if (!humanKey) return false;
  try {
    await axios.post('/api/captcha/', { humanKey });
    return true;
  } catch {
    return false;
  }
}

interface Props {
  callback: (isHuman: boolean) => void;
}

export default function Captcha(props: Props) {
  const { callback } = props;

  const onChange = async (humanKey: any) => {
    const isHuman = await check(humanKey);
    callback(isHuman);
  };

  return (
    <ReCAPTCHA
      sitekey={RECAPTCHA_CLIENT_KEY}
      onChange={onChange}
      size="compact"
    />
  );
}
