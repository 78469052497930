import React from 'react';
import { styled } from 'linaria/react';

const Toaster = styled.div`
  z-index: 500;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  .innerText {
    width: 100%;
    margin: 0 15px;
    max-width: 850px;
    padding: 20px;
    font-size: 24px;
    letter-spacing: -1px;
    font-family: monospace;
    color: #fff;
    background-color: #000;
    border-radius: 2px;
    text-align: center;
    opacity: 1;

    &--hide {
      animation: animation 3s cubic-bezier(0.98, 0.01, 0.53, 0.47);
    }
    .short {
      font-size: 1.7rem;
      letter-spacing: -0.75px;
      line-height: 1.2;
      font-family: var(--font-heading);
    }
    .long {
      color: var(--gray);
    }
    .btn {
      padding: 0.5rem 2rem;
      border-radius: 0;
      border: 0;
      border: 4px solid var(--green);
      transition: background 0.2s linear;
      &:hover {
        background: var(--green);
      }
    }
  }

  @keyframes animation {
    0%,
    50% {
      opacity: 1;
    }
    50%,
    100% {
      opacity: 0;
    }
  }
`;

interface Props {
  message: string;
  longMessage: string;
  withButton: boolean;
  callback: () => void;
}

const Toast = (props: Props) => {
  const { message, longMessage, withButton, callback } = props;
  const [visible, setVisible] = React.useState(true);
  const onButtonClick = () => {
    setVisible(false);
    callback();
  };
  return (
    <Toaster>
      <div className={visible ? 'innerText' : 'innerText innerText--hide'}>
        <h4 className="short">{message}</h4>
        <p className="long">{longMessage}</p>
        {withButton && <button className="btn" onClick={onButtonClick}>OK</button>}
      </div>
    </Toaster>
  );
};

Toast.defaultProps = {
  withButton: false,
  message: '',
  longMessage: '',
};

export default Toast;
