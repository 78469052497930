import React from 'react';
import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';
import ReactMarkdown from 'react-markdown';
import { styled } from 'linaria/react';

const EditorWrapper = styled.div``;

interface Props {
  value: string;
  setValue: (newVal: string) => void;
  messages: string[];
}

const RichTextEditor = (props: Props) => {
  const { value, setValue, messages } = props;
  const [selectedTab, setSelectedTab] = React.useState<'write' | 'preview'>(
    'write'
  );
  const hasErrors = messages.length > 0;
  return (
    <EditorWrapper className={hasErrors ? 'container danger' : 'container'}>
      <ReactMde
        value={value}
        onChange={setValue}
        toolbarCommands={[
          ['bold', 'italic', 'strikethrough'],
          ['link', 'quote', 'code'],
          ['unordered-list', 'ordered-list'],
        ]}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(<ReactMarkdown source={markdown} />)
        }
        maxEditorHeight={100}
        minPreviewHeight={100}
      />
      {hasErrors && <p className="error-msgs">{messages.join(' ')}</p>}
    </EditorWrapper>
  );
};

RichTextEditor.defaultProps = {
  messages: [],
};
export default RichTextEditor;
