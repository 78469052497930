import React from 'react';
import { styled } from 'linaria/react';
// @ts-ignore
import IosSettings from 'react-ionicons/lib/IosSettings';
import FormButtonCaptcha from './FormButtonCaptcha';
const FormButtonWrapper = styled.div`
  margin-top: 1rem;
  max-width: 156px;
`;

const FormSubmitButton = styled.button`
  border-radius: 2px;
  // border: 1px solid #c8ccd0;
  border: none;
  background: var(--green);
  padding: 11px;
  cursor: pointer;
  width: 100%;
  transition: background 0.2s linear;
  max-height: 40px;
  &:hover {
    background: var(--green-dark);
  }
`;

interface Props {
  title: string;
  processing: boolean;
  onClick: () => void;
}

const FormButton = (props: Props) => {
  const [showCaptcha, setShowCaptcha] = React.useState(false);
  const { title, onClick: onConfirmed, processing } = props;

  const onClick = () => {
    setShowCaptcha(true);
  };

  const onCaptchaSubmit = (isHuman: boolean) => {
    if (isHuman) {
      onConfirmed();
    } else {
      setShowCaptcha(false);
    }
  };

  const content = processing ? (
    <IosSettings rotate={true} />
  ) : (
    <span>{title}</span>
  );

  return (
    <FormButtonWrapper>
      {showCaptcha ? (
        <FormButtonCaptcha callback={onCaptchaSubmit} />
      ) : (
        <FormSubmitButton onClick={onClick}>{content}</FormSubmitButton>
      )}
    </FormButtonWrapper>
  );
};

export default FormButton;
