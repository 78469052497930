import React from 'react';
import ReactMarkdown from 'react-markdown';
import { styled } from 'linaria/react';

const CommentBox = styled.div`
  margin: 1rem 0;
  h4 {
    margin: 0;
  }
  .tilde {
    color: var(--green);
  }
  .message {
    margin-left: 1rem;
  }
`;

interface Comment {
  author: string;
  message: string;
  date: Date;
}

interface Props {
  comment: Comment;
}

const CommentCard = (props: Props) => {
  const { comment } = props;
  return (
    <CommentBox>
      <h4>
        <span className="tilde">{` > `}</span>{comment.author} - {comment.date}
      </h4>
      <ReactMarkdown source={comment.message} className="message" />
    </CommentBox>
  );
};

export default CommentCard;
